<template>
  <div>
    <CCard body-wrapper>
      <CRow align-vertical="center" class="mb-3">
         <CCol col="auto" class="flex-grow-1">
           <h1>
             Фулфилменты
           </h1>
         </CCol>
         <CCol col="auto">
           <CButton
             color="primary"
             :to="{ name: 'admin.warehouses.create.one_s'}"
           >
             + Добавить
           </CButton>
         </CCol>
       </CRow>
      <CDataTable
        :loading="loading"
        :items="list"
        :fields="tableFields"
        striped
        hover
      >
        <template #loading>
          <CElementCover
            :opacity="0.8"
          >
            <CSpinner
              class="custom-spinner"
              color="info"
            />
          </CElementCover>
        </template>
        <template #no-items-view>
          <div class="text-center d-flex align-items-center justify-center flex-column">
            <span class="font-xl mb-2">Список пуст</span>
            <CButton
              color="primary"
              :to="{ name: 'admin.warehouses.create.one_s'}"
            >
              + Создать
            </CButton>
          </div>
        </template>
        <template #actions="{ item }">
          <td class="align-middle text-right">
            <CButton
              color="secondary"
              title="Подробнее"
              :to="{ name: 'admin.warehouses.show',params: { id: item.id } }"
            >
              <CIcon name="cilPencil" />
            </CButton>
          </td>
        </template>
        <template #active="{ item }">
          <td class="align-middle">
            <CSwitch
              color="primary"
              :checked="item.active"
              @update:checked="changeActivity(item)"
            />
          </td>
        </template>
        <template #storesReady="{ item }">
          <td class="align-middle">
            <CBadge class="ml-2" :color="item.storesReady ? 'primary' : 'danger'">
              {{item.storesReady ? '✓' : '×'}}
            </CBadge>
          </td>
        </template>
        <template #source="{ item }">
          <td class="align-middle">
            <CLink :to="{name: 'admin.one_s_setting.show', params: { id: item.sourceId }}">
              1C
            </CLink>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'All',
  data(){
    return {
      list: [],
      loading: false,
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    tableFields() {
      return [
        {key: 'active', label: 'Включен'},
        {key: 'source', label: 'Источник'},
        {key: 'name', label: 'Название', _classes: ['font-weight-bold', 'align-middle']},
        {key: 'storesReady', label: 'Склады настроены'},
        {key: 'actions', label: 'Действия', _style: 'text-align: right;'}
      ]
    }
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const {data} = await this.$axios.request({url: 'api/warehouses'});
        this.list = data;
      } finally {
        this.loading = false;
      }
    },
    async changeActivity(warehouse) {
      const {data} = await this.$axios.put(`api/warehouses/${warehouse.id}`, {active: !warehouse.active});
      const index = this.list.findIndex(el => el.id == warehouse.id);

      if (index !== -1) {
        this.list.splice(index, 1, data);
      }
    }
  }
}
</script>
