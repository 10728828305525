var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"body-wrapper":""}},[_c('CRow',{staticClass:"mb-3",attrs:{"align-vertical":"center"}},[_c('CCol',{staticClass:"flex-grow-1",attrs:{"col":"auto"}},[_c('h1',[_vm._v(" Фулфилменты ")])]),_c('CCol',{attrs:{"col":"auto"}},[_c('CButton',{attrs:{"color":"primary","to":{ name: 'admin.warehouses.create.one_s'}}},[_vm._v(" + Добавить ")])],1)],1),_c('CDataTable',{attrs:{"loading":_vm.loading,"items":_vm.list,"fields":_vm.tableFields,"striped":"","hover":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"opacity":0.8}},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info"}})],1)]},proxy:true},{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center d-flex align-items-center justify-center flex-column"},[_c('span',{staticClass:"font-xl mb-2"},[_vm._v("Список пуст")]),_c('CButton',{attrs:{"color":"primary","to":{ name: 'admin.warehouses.create.one_s'}}},[_vm._v(" + Создать ")])],1)]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle text-right"},[_c('CButton',{attrs:{"color":"secondary","title":"Подробнее","to":{ name: 'admin.warehouses.show',params: { id: item.id } }}},[_c('CIcon',{attrs:{"name":"cilPencil"}})],1)],1)]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('CSwitch',{attrs:{"color":"primary","checked":item.active},on:{"update:checked":function($event){return _vm.changeActivity(item)}}})],1)]}},{key:"storesReady",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('CBadge',{staticClass:"ml-2",attrs:{"color":item.storesReady ? 'primary' : 'danger'}},[_vm._v(" "+_vm._s(item.storesReady ? '✓' : '×')+" ")])],1)]}},{key:"source",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('CLink',{attrs:{"to":{name: 'admin.one_s_setting.show', params: { id: item.sourceId }}}},[_vm._v(" 1C ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }